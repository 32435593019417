





























































import useAbstract from "@/use/abstract";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    participantId: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, { root }) {
    const { getTypeName, getStatusName } = useAbstract({ root });

    const state = reactive({
      success: false,
      error: false as boolean | number,
      participant: {},
      loading: true,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${props.participantId}`)
        .then(({ data: { participant } }) => {
          state.participant = participant;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };
    return { getTypeName, getStatusName, getFile, state };
  },
});
